import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

function loadFile(filePath) {
  var result = null;
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.open("GET", filePath, false);
  xmlhttp.send();
  if (xmlhttp.status == 200) {
    result = xmlhttp.responseText;
  }
  return result;
}

var experienceJson = loadFile("./experience.json");

//console.log(experienceJson)

Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight
    },
    experienceJson: experienceJson
  }

});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
